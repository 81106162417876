var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"odd"},[_c('button',{class:['btn odd-btn', _vm.statusClass, _vm.picked],attrs:{"disabled":_vm.locked,"id":_vm.getOddID(
        _vm.match_id,
        _vm.market_id,
        _vm.outcome_id,
        _vm.specifier,
        _vm.boosted ? 'boosted' : 'odd'
      ),"oddStatus":_vm.oddStatus,"oddValue":_vm.odd,"oddActive":_vm.oddActive,"producerId":_vm.producer_id,"producerStatus":_vm.producerStatus},on:{"click":function($event){return _vm.addPick(
        _vm.sport_id,
        _vm.match_id,
        _vm.market_name,
        _vm.market_id,
        _vm.home_team,
        _vm.away_team,
        _vm.producer_id,
        _vm.specifier,
        _vm.pick,
        _vm.live
      )}}},[_c('div',{staticClass:"grey-text text-center odd-indicator"},[_vm._v(_vm._s(_vm.outcome_name))]),_c('button',[(_vm.locked)?_c('span',{staticStyle:{"height":"1.5em"}},[_c('LockIcon',{attrs:{"size":_vm.iconSize,"fill-color":"black"}})],1):_c('span',[_c('p',{staticClass:"odd-type",staticStyle:{"margin":"0","padding":"0"}},[(_vm.boostedGame)?_c('span',[_vm._v("🔥")]):_vm._e(),_c('span',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('span',{staticStyle:{"display":"block","text-wrap":"wrap","word-break":"break-all"}},[_vm._v(" "+_vm._s(_vm.alias))]),_c('span',{staticClass:"odd-direction",class:_vm.directionClass,staticStyle:{"margin-left":"5px"}})])]),_c('span',{staticClass:"odd-number",staticStyle:{"width":"20px"}},[_vm._v(_vm._s(_vm.odd))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }